<template>
  <div class="main">
    <div id="roll" class="app-container">
      <div class="top_botton">
        <ul>
          <li v-for="(item,index) in rollData " :key='index'
          :class="{'active':index==rollSelect}"
          @click="choiceRoll(index)"
          v-if="index < 3 || nametype == 1"
          >{{item}}</li>
        </ul>
        <ul>
          <li v-if= "nametype == 1" class="active" style="margin-right: 65px;" @click="estabRoll">创建Roll房</li>
        </ul>
      </div>
      <!-- 创建roll房 -->
      <establishRoll v-if="isestabRoll" :isestabRoll.sync="isestabRoll"></establishRoll>
      <!-- roll房列表 -->
      <div class="rollListBox">
        <div class="ant-spin-nested-loading" style="width: 100%">
          <div class="ant-spin-container">
            <ul class="rollList">
              <!-- <li class="isOfficial">
                <div class="title">
                  <div
                    class="avatar-container title__avatar"
                    style="width: 24%"
                  >
                    <div class="avatar avatar_status-official">
                      <div
                        class="avatar__picture"
                        style="
                          background-image: url('http://api.r8skins.com/uploads/20210504/72fc9e7d47c7801838bcbd361fd1ce3e.png');
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="messageBox">
                  <div class="nameBox">
                    <p title="5月仟元福利回血" class="title">5月仟元福利回血</p>
                    <div class="centerMessage">
                      <div>
                        <span>奖池</span>
                        <p>5986.89</p>
                      </div>
                      <div>
                        <span>件数</span>
                        <p>20</p>
                      </div>
                      <div>
                        <span>人数</span>
                        <p>16</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="imgBox">
                  <div class="roll_line1">
                    <img
                      src="http://api.r8skins.com/uploads/20210504/a7cc26e89f11713c7b5fa67ead6367ad.png"
                      alt=""
                    />
                  </div>
                  <div class="roll_line1">
                    <img
                      src="http://api.r8skins.com/uploads/20210504/40ae006ca77497ddcf3b9b497ad72d87.png"
                      alt=""
                    />
                  </div>
                  <div class="roll_line5">
                    <img
                      src="http://api.r8skins.com/uploads/20210430/fbf25d0b6d07c3fc608a4749bddb6486.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="enterButtonBox">
                  <button class="enter">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>我要参加</span></button
                  >
                </div>
                <p class="time">开奖时间: 2021-05-14 17:00:00</p>
              </li> -->
              <li v-show="!userprize" class="isOfficial" v-for="(item,index) in caseData" :key="'s' + index"
              @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                <div class="title">
                  <div
                    class="avatar-container title__avatar"
                    style="width: 24%"
                  >
                    <div class="avatar avatar_status-official">
                      <div
                        class="avatar__picture"
                        :style="{backgroundImage:'url('+item.logo+')'}"
                        :class="{'avatar__pictures':item.title.indexOf('官方') !== -1 || item.title.indexOf('cxskins') !== -1}"
                      ></div>
                    </div>
                    <p class="avatar__picture_p" v-if="item.title.indexOf('官方') != -1 || item.title.indexOf('cxskins') != -1">官方</p>
                  </div>
                </div>
                <div class="messageBox">
                  <div class="nameBox">
                    <p :title=item.title class="title">{{item.title}}</p>
                    <div class="centerMessage">
                      <div>
                        <span>奖池</span>
                        <p>{{item.data.sumCoin}}</p>
                      </div>
                      <div>
                        <span>件数</span>
                        <p>{{item.data.count}}</p>
                      </div>
                      <div>
                        <span>人数</span>
                        <p>{{item.people}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="imgBox">
                  <!-- <div class="roll_line1" >
                    <img
                      src="http://api.r8skins.com/uploads/20210504/a7cc26e89f11713c7b5fa67ead6367ad.png"
                      alt=""
                    />
                  </div> -->
                  <div v-for="(items,indexs) in item.data" :key="indexs" v-if="indexs < 3"  :class="'roll_line' + indexs "
                  :style="{backgroundImage:'url('+items.back_logo+')'}"
                  >
                    <img
                      :src=items.logo
                      alt=""
                    />
                    <!-- <p>1{{items.count}}</p> -->
                  </div>
                </div>
                <div class="enterButtonBox">
                    <button class="enter"
                    v-if="currentTime > Date.parse(new Date(item.start_time.replace(/-/g, '/'))) && currentTime < Date.parse(new Date(item.end_time.replace(/-/g, '/'))) && rollSelect == 1 "
                    @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>我要参加</span>
                    </button>
                    <button class="enter" style="background:#666;" v-if="rollSelect != 3 && rollSelect != 4 && currentTime < Date.parse(new Date(item.start_time.replace(/-/g, '/')))" @click="$router.push({path:'/rollDetail',query:{id:item.id}})

                    ">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>未开启</span>
                    </button>

                    <button class="enter" style="background:rgb(41 40 40);" v-if="rollSelect  == 3" @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>审核中</span>
                    </button>

                    <button class="enter" style="background:rgb(255, 152, 56);" v-if="rollSelect  == 4" @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>查看详情</span>
                    </button>

                    <!-- <button class="enter"  style="background:#ff9838;"
                    v-if="rollcurrents == 0"
                    @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>已参加</span>
                    </button> -->

                    <button class="enter" style="background:#666;" v-if="rollSelect == 2" @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAilBMVEUAAADs1a3u2rXs1a7q1a3s1q7s1a7s1a7t1a7s1a7s1a7t1q7/2Kft1a7s1q7s1a7s1a7s1a/s1a7s1a7t1q7s1q7s1q7s1a/s1q7s1q/s16zs1a7s1q/s1q7t1a7s1q/r1q3r1a3p3a7s1a7s1a7s1a/s1K3t1a/s1a/s1K/s1K7s1a/q1bDs1a4hOZq4AAAALXRSTlMA9A7rF77cw2Tpm4wDmHr65eHHsaeRXbmtax7ty6B/dE0yB/fWzoMrpWBfQz1nnERKAAABL0lEQVRIx+3U2Y6CMBiG4aIIFBVENmV1XGf77v/2hnQysTSd/jTO0cT3zM88ARuE/fsO/TLvCpdNze3OEDnh5zQR1ACSNPNjANEUcQWQfYir9Q4Q0iIAEPx8qC4TzEwSQysPOBBkDaSjowDilVFUQHwcLRF1mQDYKRNHaSQh8K5MPmrjnfnATZkWgGsiZ/G9Sm4m4mnJy98T9beWgEuQajbOB4rfjuy4bLwYcJSGCTzTowsMNTqzh7FAQ1IzWWrI4lFSX6PEjmzd4QRbK1KJaUMQH/fm39OrNOUEcaRDNJAtpAoxnSyIeHjdVB7WJIkYK2BH9oy9kcRXSWBN1BsjH5hMvPSkQpIg704kKaFGkp2ZtPYk05Ce+iPbXqZk2qLGS5JkM8T5/B7n3GvZswf6Aq2LgFx6gbWkAAAAAElFTkSuQmCC"
                    /><span>已结束</span>
                    </button>
                </div>
                <p class="time" v-if="currentTime > Date.parse(new Date(item.end_time.replace(/-/g, '/')))">开奖时间: {{item.end_time}}</p>
                <p class="time" v-if="currentTime < Date.parse(new Date(item.end_time.replace(/-/g, '/')))">开启时间: {{item.start_time}}</p>
              </li>
              <!-- 我的roll 中奖页面单独写 -->
              <li v-show="userprize" class="isOfficial" v-for="(item,index) in caseData" :key="index"
              >
                <div class="title" >
                  <div
                    class="avatar-container title__avatar"
                    style="width: 24%"
                  >
                    <div class="avatar avatar_status-official">
                      <div
                        class="avatar__picture"
                        :style="{backgroundImage:'url('+item.logo+')'}"
                        :class="{'avatar__pictures':item.title.indexOf('官方') !== -1 || item.title.indexOf('cxskins') !== -1}"
                      ></div>
                    </div>
                    <p class="avatar__picture_p" v-if="item.title.indexOf('官方') !== -1 || item.title.indexOf('cxskins') !== -1">官方</p>
                  </div>
                </div>
                <div class="title" style="height:1px;" v-if="item.win != 0 ">
                  <div
                    class="avatar-container title__avatar"
                    style="width: 24%;right: -5%;top: -40%;"
                  >
                    <div class="avatar avatar_status-official">
                      <div
                        class="avatar__picture"
                        style="top: -10px;left: 5px;"
                      >
                      <i class="iconfont icon-zhongjiangliao" style="font-size: 45px;color: red;" v-if="item.win == 2 "></i>
                      <i class="iconfont icon-weizhongjiang" style="font-size: 45px;color: #666;" v-if="item.win == 1"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="messageBox">
                  <div class="nameBox">
                    <p :title=item.title class="title">{{item.title}}</p>
                    <div class="centerMessage">
                      <div>
                        <span>奖池</span>
                        <p>{{item.data.sumCoin}}</p>
                      </div>
                      <div>
                        <span>件数</span>
                        <p>{{item.data.count}}</p>
                      </div>
                      <div>
                        <span>人数</span>
                        <p>{{item.people}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="imgBox" v-if="item.win == 0 ">
                  <div v-for="(items,indexs) in item.data" :key="indexs" v-if="indexs < 3"  :class="'roll_line' + indexs"
                  :style="{backgroundImage:'url('+items.back_logo+')'}"
                  >
                    <img
                      :src=items.logo
                      alt=""
                    />
                  </div>
                </div>
                <!-- 中奖时 -->
                <div class="imgBox" v-if="item.win == 2 " style="justify-content: space-around;">
                  <div v-for="(items,indexs) in item.data" :key="indexs" v-if="indexs < 1"  :class="'roll_line' + indexs"
                  :style="{backgroundImage:'url('+items.back_logo+')'}"
                  >
                    <img
                      :src=items.logo
                      alt=""
                    />
                  </div>
                </div>
                <!-- 不中奖时 -->
                <div class="imgBox" v-if="item.win == 1 " style="justify-content: space-around;">
                  <!-- <div v-for="(items,indexs) in item.data" :key="indexs" v-if="indexs < 1"  :class="'roll_line' + indexs"
                  :style="{backgroundImage:'url('+items.back_logo+')'}"
                  >
                    <img
                      :src=items.logo
                      alt=""
                    />
                  </div> -->
                </div>

                <div class="enterButtonBox">
                    <button class="enter"  style="background:#ff9838;"
                    v-if="currentTime < Date.parse(new Date(item.end_time.replace(/-/g, '/')))"
                    @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <span>等待开奖</span>
                    </button>
                    <button class="enter"  style="background:#ff9838;"
                    v-if="currentTime > Date.parse(new Date(item.end_time.replace(/-/g, '/')))"
                    @click="$router.push({path:'/rollDetail',query:{id:item.id}})">
                    <span>查看详情</span>
                    </button>
                </div>
                <p class="time" v-if="currentTime > Date.parse(new Date(item.start_time.replace(/-/g, '/')))">开奖时间: {{item.end_time}}</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 分页 -->
        <div class="pageBox" v-if="this.rollcurrents != 1">
          <ul class="ivu-page" style="padding-top:20px">
                <li title="1" class="ivu-page-item ivu-page-item-active">
                  <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    @current-change="pagedata"
                    :total="total"
                    :page-size="limit"
                  >
                  </el-pagination>
                </li>
              </ul>
        </div>
      </div>

    </div>
    <!-- 红包 -->
    <Redenvelopes></Redenvelopes>
  </div>
</template>

<script>
import Redenvelopes from "../components/Redenvelopes";
import establishRoll from "../components/establishRoll";

export default {
  components: {
    Redenvelopes,
    establishRoll
  },
  data() {
    return {
      rollData:['我参加的','进行中','已结束','待审核','我创建的'],
      rollcurrents:'1',//参加 进行 结束
      total:10,//分页测试
      page:1,//分页
      limit:10,//一页多少条
      ispawd:true,//是否需要密码
      ss:'2021-05-14 17:00:00',//测试
      caseData:{},//箱子数据
      userprize:false,//用户中奖页面
      //服务器时间
      currentTime: Date.parse(new Date()),
      nametype:this.$store.state.userdata.type,//用户权限
      isestabRoll:false,//创建roll房表单
      rollSelect:'1',//roll选中
    };
  },
  // watch:{
  //   rollcurrents(a,b){
  //     this.rollSelect = a
  //     console.log(a);
  //   }
  // },
  methods: {
    // 红包
    redPrize(){
      document.getElementsByClassName('ivu-modal')[0].style.display="block";
      document.getElementById('isquan').classList.add("backghui")
    },
    //获取分类
    choiceRoll(index){
      this.rollcurrents = index
      this.rollSelect = index
      this.page = 1
      this.total = 1
      if(index != 0 && index != 3 && index != 4){
        this.getdata();
        this.userprize =false
      }else if(index == 0){
        this.caseData = {}
        if(localStorage.getItem("token") == null){
          this.rollcurrents = 1
          this.rollSelect = 1
          this.getdata()
          this.$message({
            showClose: true,
            message: '请先登录',
            type: 'error'
          });
        }else{
          this.limit = 10
          this.$axios({
          url:'/roll/user/getRollHome',
          method:"post",
          params:{
          page:this.page,
          limit:this.limit
        }
          }).then( res =>{
            if(res.data.status == 200){
              this.caseData = res.data.data
              this.userprize =true
              this.total = res.data.count
              this.nametype = this.$store.state.userdata.type
            }
          })
        }
      }else if(index == 3){
        this.rollcurrents = 9
        this.userprize =false
        this.getdata()
      }
      else if(index == 4){
        this.userprize =false
        this.rollcurrents = 10
        this.getdata()
      }
    },
    //拿到当前页数  十条的话就this.totals = key*10   上面v-if 减十v-if="index+1 > totals -10 && index < totals"
    pagedata(key){
      this.page = key
      if(this.rollcurrents != 0){
        this.getdata()
      }else{
        if(localStorage.getItem("token") == null){
          this.rollcurrents = 1
          this.$message({
            showClose: true,
            message: '请先登录',
            type: 'error'
          });
        }else{
          this.limit = 10
          this.$axios({
          url:'/roll/user/getRollHome',
          method:"post",
          params:{
          page:this.page,
          limit:this.limit
        }
          }).then( res =>{
            if(res.data.status == 200){
              this.caseData = res.data.data
              this.userprize =true
              this.total = res.data.count
              this.nametype = this.$store.state.userdata.type
            }
          })
        }
      }
    },
    //获取页面数据
    getdata(){
      this.caseData = {}
      if(this.rollcurrents == 1){
        this.limit = 0
      }else{
        this.limit = 10
      }
      this.$axios({
        url:'/roll/getList',
        method:"post",
        params:{
          type:this.rollcurrents,
          page:this.page,
          limit:this.limit
        }
      }).then( res =>{
        if(res.data.status == 200){
          this.caseData = res.data.data
          this.total = res.data.count
          this.nametype = this.$store.state.userdata.type
        }
      })
    },
    estabRoll(){
      this.isestabRoll = true
    },
  },
  //判断是否哪个页面过来
  // beforeRouteEnter(to,from,next){
  //   if(from.name == 'rollDetail'){
  //     to.meta.isBack = true;
  //   }
  //   next();
  // },
  // activated(){
  //   if(!this.$route.meta.isBack){
  //     this.$axios({
  //       url:'/roll/getList',
  //       method:"post",
  //       params:{
  //         type:0,
  //         page:this.page,
  //         limit:this.limit
  //       }
  //     }).then( res =>{
  //       if(res.data.status == 200){
  //         this.caseData = res.data.data
  //         this.total = res.data.count
  //       }
  //     })
  //   }
  //   this.$route.meta.isBack = false
  // },
  // created() {
  //   this.getdata()
  //   console.log(this.$store.state.userdata.type);
  // },
  //创建roll
  mounted(){
    this.getdata()
  }
};
</script>
<style scoped lang='less'>
@import url("../assets/csscopy/Roll.css");
//创建roll房
.top_botton{
  width: 100%;
}
.top_botton ul li{
  width: 100px !important;
}
.avatar__pictures{
  border-radius: 50% !important;
  border: 1px #E9B10E solid !important;
}
.avatar__picture_p{
  position: absolute;
  bottom: -5px;
  left:calc(50% - 20px);
  background-color: #E9B10E;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 50px;
  color: #000;
}
@media screen and (max-width: 800px) {
    .isOfficial{
      width: 100% !important;
      margin-right: 0 !important;
    }
    .ivu-page{
      padding-right: 0 !important;
      padding-bottom: 0  !important;
      justify-content: space-around !important;

    }
    #roll .top_botton, #roll .top_botton ul{
      flex-wrap: wrap;
      justify-content: center;
    }
    .top_botton ul li{
      margin-right: 0px !important;
      margin: 5px;
    }
    #roll .rollListBox .rollList li>.title{
      height: 70px;
    }
}
</style>
