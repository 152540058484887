<template>
<div class="backghui">
  <div class="big">
    <div class="form_title">
        <h1>创建房间</h1>
        <i class="iconfont icon-guanbi" @click="outaddroll"></i>
    </div>
    <div class="form_data">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="房间名" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入房间名"></el-input>
            </el-form-item>
            <el-form-item label="房间描述" prop="describe">
                <el-input v-model="ruleForm.describe" placeholder="请输入房间描述(可选)"></el-input>
            </el-form-item>
            <el-form-item label="房间密码" prop="pass">
                <el-input type="password" placeholder="可选(密码3位以上,为空则无密码)" v-model="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            
            <el-form :inline="true"  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <!-- <el-form-item label="获胜人数">
                    <el-input-number v-model="num" @change="handleChange" :min="1" label="描述文字"></el-input-number>
                </el-form-item> -->
                <el-form-item label="参加人数上限">
                    <el-input-number v-model="num2" :min="0"  label="描述文字"></el-input-number>
                    <span style="padding-left: 5px;color: #666;font-size: 12px;">默认为0(0为无上限)</span>
                </el-form-item>
            </el-form>
            
            <el-form-item label="活动时间" required>
                <el-date-picker
                v-model="ruleForm.valuetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 62%;"
                :clearable='false'
                >
                </el-date-picker>
            </el-form-item>
            
            <el-form-item label="设置门槛加入" prop="delivery">
                <el-switch v-model="delivery"></el-switch>
            </el-form-item>

            <el-form-item label="Roll房门槛" prop="region" v-if="delivery">
                <el-input v-model="ruleForm.region" placeholder="请输入充值门槛(整数)"></el-input>
            </el-form-item>
        </el-form>
        <div style="text-align: center;">
        <el-button type="primary" @click="submitForm('ruleForm')" v-if="isrepages">立即创建</el-button>
        <el-button type="primary" v-else>创建中<i class="el-icon-loading"></i></el-button>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    props:['isestabRoll'],
    data() {
      return {
        isrepages:true,//是否在创建中
        delivery: false,//门槛
        ruleForm: {
          name: '',//房间名
          describe:'',//房间描述
          pass:'',//密码
          valuetime:'',//时间
          region: '',//门槛要求
        },
        num:1,//获胜人数
        num2:0,//参加人数
        rules: {
          name: [
            { required: true, message: '请输入房间名', trigger: 'blur' },
            { min: 3, message: '长度在 3 个字符及以上', trigger: 'blur' }
          ],
          describe: [
            { required: true, message: '请输入房间描述', trigger: 'blur' },
            { min: 5, message: '长度在 5 个字符及以上', trigger: 'blur' }
          ],
          pass: [
            { min: 3, message: '长度在 3 个字符及以上', trigger: 'blur' }
          ],
          valuetime: [
            { required: true, message: '请选择日期', trigger: 'change' }
          ],
          region: [
            { required: true, message: '请输入充值门槛', trigger: 'change' },
            {validator:this.validatenumber,trigger: 'change'}
          ],

        }
      };
    },
    watch:{
        //监听是否需要等级要求
        delivery(){
            if(!this.delivery){
                this.ruleForm.region = ''
            }
        }
    },
    methods: {
        //表单整数效验
        validatenumber(rule,value,calback){
            let reg = /^[1-9]([0-9])*$/
            let flag = reg.test(value);
            if(!flag){
                calback(new Error('须为整数'))
            }else{
                calback()
            }
        },
      //表单提交
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');  
            // console.log(this.ruleForm);
            if(this.ruleForm.valuetime && Date.parse(this.ruleForm.valuetime[1]) > Date.parse(new Date())){ //判断日期
                if(this.isrepages){ //判断是否在请求中 避免一直请求
                    //处理密码
                    if(this.ruleForm.pass == ''){
                        this.ruleForm.pass = 0
                    }
                    //处理金额
                    if(this.ruleForm.region == ''){
                        this.ruleForm.region = 0
                    }
                    this.isrepages = false
                    this.$axios({
                        url:'roll/create/Roll',
                        method:'post',
                        data:{
                            title:this.ruleForm.name,
                            info:this.ruleForm.describe,
                            pwd:this.ruleForm.pass,
                            pmax:this.num2,
                            pay_min:this.ruleForm.region,
                            start_time:Date.parse(this.ruleForm.valuetime[0]),
                            end_time:Date.parse(this.ruleForm.valuetime[1])
                        }
                    }).then( res => {
                        if(res.data.status == 200){
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: "success",
                            });
                            this.$emit('update:isestabRoll', false);
                        }else{
                            this.$message({
                                type: 'error',
                                showClose: true,
                                message:res.data.msg,
                            });
                        }
                        this.isrepages = true
                    })
                }
                
            }else{
                this.$message({
                    type: 'error',
                    showClose: true,
                    message:'请输入正确的日期'
                });
            }
          } else {
            return false;
          }
        });
      },
    //   handleChange(value) {
    //     console.log(value);
    //   },
      //关闭创建
      outaddroll(){
        this.$emit('update:isestabRoll', false);
      }
    },
    
}
</script>

<style lang="less" scoped>
.backghui{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
}
.big{
    width: 50%;
    background-color: #1E1D2A;
    margin:10vw auto;
}
.form_data{
    padding: 50px;
}
.form_title{
    display: flex;
    justify-content: space-between;
    padding:10px 20px;
    background-color: #282736;
    align-items: center;
    color: #D0D2E0;
    h1{
        font-size: 20px;
    }
    i{
        font-size: 25px;
        cursor:pointer;
    }
}
/deep/ .el-input__inner{
    background-color: #14181E;
    border: 1px solid #14181E;
}
/deep/ .el-input-number__decrease{
    background: #14181E;
}
/deep/ .el-input-number__increase{
    background: #14181E;
}
/deep/ .el-range-input{
    background: #14181E;
}
/deep/ .el-input-number__increase{
    border-left: 1px solid #14181E;
}
/deep/ .el-input-number__decrease{
    border-right: 1px solid #14181E;
}
/deep/ .el-picker-panel{
        left: 0px !important;
    }
@media screen and (max-width: 800px) {
    .big{
        margin: 20vw auto;
        width: 100%;
    }
    .form_data{
        padding: 0;
    }
}
</style>